/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

require('jquery-datetimepicker');

var CREATIVX = CREATIVX || {};

(function($){

    // USE STRICT
    "use strict";

    var $window = $(window);

    CREATIVX.documentOnReady = {
        init: function(){
            CREATIVX.documentOnReady.mydatepicker();

        },

        mydatepicker: function(){
            jQuery.datetimepicker.setLocale('de');
            jQuery('.datepicker').datetimepicker({
                i18n:{
                    de:{
                        months:[
                            'Januar','Februar','März','April',
                            'Mai','Juni','Juli','August',
                            'September','Oktober','November','Dezember',
                        ],
                        dayOfWeek:[
                            "So.", "Mo", "Di", "Mi",
                            "Do", "Fr", "Sa.",
                        ]
                    }
                },
                timepicker:false,
                format:'d.m.Y'
            });
        },
    };

    $(document).ready( CREATIVX.documentOnReady.init );

})(jQuery);

$(document).ready(function() {

    jQuery.datetimepicker.setLocale('de');
    jQuery('.datetimepicker').datetimepicker({
        i18n:{
            de:{
                months:[
                    'Januar','Februar','März','April',
                    'Mai','Juni','Juli','August',
                    'September','Oktober','November','Dezember',
                ],
                dayOfWeek:[
                    "So.", "Mo", "Di", "Mi",
                    "Do", "Fr", "Sa.",
                ]
            }
        },
        format:'d.m.Y H:i',
        scrollMonth : false,
        scrollInput : false,
    });
    jQuery('.datepicker').datetimepicker({
        i18n:{
            de:{
                months:[
                    'Januar','Februar','März','April',
                    'Mai','Juni','Juli','August',
                    'September','Oktober','November','Dezember',
                ],
                dayOfWeek:[
                    "So.", "Mo", "Di", "Mi",
                    "Do", "Fr", "Sa.",
                ]
            }
        },
        timepicker:false,
        format:'d.m.Y',
        scrollMonth : false,
        scrollInput : false,
    });

    // Toggle Task Entrys
    $('.tasklog-toggle').click(function(){
        var classtarget = $(this).attr('data-target');
        $('.entrys').removeClass('open');
        $('.entrys.' +classtarget).addClass('open');
    });

    /** Set Origin Field Name to Frm **/
    $('a[data-toggle=modal], button[data-toggle=modal]').click(function () {

        var data_id = '';

        if (typeof $(this).data('origin') !== 'undefined') {

            data_origin = $(this).data('origin');
        }

        $('#task_projectField').val(data_origin);
    })


});





